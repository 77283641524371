@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-image: url(Assets/header-hero.jpg);
  background-position: center center;
  background-size: cover;
}

:root {
  --third: #FF9186;
  --main: #f14836;
  --second: #f14836;
  --font: 'Nunito', sans-serif;
}

.main-font {
  font-family: 'Nunito', sans-serif;
}

.btn-main {
  background-color: var(--main);
  color: #fff;
  transition: all 1s;
}
.btn-main:disabled {
  background-color: var(--main);
  color: #fff;
  transition: all 1s;
}

.btn-outline-main {
  color: var(--main);
  background-color: #fff;
  border: var(--main) 1px solid;
  transition: all 1s;
}

.btn-outline-main:hover {
  background-color: var(--main);
  color: #fff;
}

.btn-outline-main:disabled {
  background-color: var(--main);
  color: #fff;
}

.bg-main {
  background-color: var(--main);
}

.bg-second {
  background-color: var(--second);
}

.bg-third {
  background-color: var(--third);
}

.text-main {
  color: var(--main);
}

.text-second {
  color: var(--second);
}

.btn-main:hover {
  border: var(--main) 1px solid;
  color: var(--main);
}

.nav-link {
  color: #222529;
  font-size: 18px;
  font-weight: 500;
}

.nav-link:hover {
  color: var(--main);
}

.nav-link:focus {
  color: #fff;
  background-color: var(--main);
  border-radius: 10px;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: var(--main);
  border-radius: 10px;
}

.main-head {
  font-size: 60px;
  /* font-weight: 800; */
}

.hover-main {
  cursor: pointer;
  transition: all 0.5s;
}

.hover-main:hover {
  color: #222529;
}

.h-line {
  position: relative;
}

.inline {
  position: relative;
  height: 4px;
  width: 100px;
  margin: 35px 20px;
  border-radius: 5px;
  display: inline-block;
}

.icon-box {
  width: 30px;
  height: 30px;
  border: 2px var(--third) solid;
  color: var(--main);
  cursor: pointer;
  transition: all 0.5s;
}

.icon-box:focus {
  background-color: var(--main);
  color: white;
}

.icon-box:hover {
  background-color: var(--main);
  color: white;
}

.head {
  font-size: 48px;
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.7;
  cursor: pointer;
}

.overlay:hover {
  opacity: 0.9;
}

.cover-img {
  width: 100%;
  background-image: url(Assets/heart-cover.jpg);
  background-position: center center;
  background-size: cover;
}

.cover-img-home {
  width: 100%;
  background-image: url(Assets/header-hero.jpg);
  background-position: center center;
  background-size: cover;
}

.opicity-gray {
  width: 100%;
}

.footer {
  font-size: 12px;
}

label {
  margin: 10px 5px;
}

input,
textarea {
  padding: 5px;
  font-size: larger;
  border: none;
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
}

input::placeholder,
textarea::placeholder {
  font-size: larger;
}

.hrz-line {
  width: 100%;
  height: 1px;
  background-color: #eaeaea;
  top: 100%;
}

.over {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

.text-green {
  color: rgb(7, 219, 7);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
  /* Remove the default background image */
}

.carousel-control-next-icon::after,
.carousel-control-prev-icon::after {
  content: '>';
  font-size: 60px;
  color: var(--main);
}

.carousel-control-prev-icon::after {
  content: '<';
}

/* Section Styling */
.app-section {
  padding: 60px 0;
    z-index: 1;
    background-color: #f8f9fa;
}

.app-title {
  font-size: 2.5rem;
  font-weight: bold;
    margin-bottom: 20px;
}

.app-description {
  font-size: 1.1rem;
  margin: 20px 0;
}

.download-btn .btn {
  margin: 10px 0;
}

/* Circular Gradient Behind Mobile App Mockup */
.circle-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background: linear-gradient(to bottom, #f9d3d3, #f26d6d);
  border-radius: 50%;
  z-index: 9;
}

.app-screenshot {
  z-index: 10;
  position: relative;
  width: 300px;
    /* max-width: 100%; */
  height: auto;
  border-radius: 10px;
}



.app-features-section {
  padding: 0 0;
    background-color: #f8f9fa;
    height: auto;
  /* The red background */
  
}

.container-feature {
  max-width: 100%;
  margin: 0 0;
}

.row-feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-screens {
  display: flex;
  gap: 30px;
  margin-left: 130px;
  /* Space between the screens */
}

.screen {
  width: 280px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 36px;
  font-weight: 700;
  color: #333;
    margin-left: 200px;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin-top: 20px;
  margin-left: 200px;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.feature-number {
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  color: #f14836;
  /* The red circle number */
  margin-right: 20px;
}

.feature-item p {
  font-size: 16px;
  color: #333;
  max-width: 400px;
}

.app-section {
  padding: 50px 0;
}

.section-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.feature-list {
  list-style: none;
  padding: 0;
}

.feature-item {
  margin-bottom: 20px;
}

.feature-number {
  font-size: 24px;
  color: #f14836;
  font-weight: bold;
  margin-right: 10px;
}

.half-red-background {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #f14836 50%, transparent 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-screenshot-container {
  position: relative;
  z-index: 2;
}

.app-screenshot {
  width: 100%;
  height: auto;
  max-width: 300px; /* Adjust the max width as per the design */
}

.red-rectangle {
  background-color: #f14836;
  /* Red background */
  padding: 100px;
  /* Adjust padding to your design */
  /* Optional: add rounded corners */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  /* Optional: add shadow */
  position: static;
}

.app-service-section {
  padding: 80px 0;
      background-color: #f8f9fa;
}

.app-store-section {
  padding: 80px 0;
      
}

.section-title-service {
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: #222529;
  margin-bottom: 40px;
}

.service-card-container {
  display: flex;
  gap: 30px;
}

.service-card {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 20px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.highlighted-card {
  background-color: #f14836;
  color: white;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card .icon-circle {
  background-color: #f14836;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.highlighted-card .icon-circle {
  background-color: white;
  color: #f14836;
}

.service-card h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.service-card p {
  font-size: 13px;
  line-height: 1.6;
}


.app-service-screenshot {
  width: 80%;
}



